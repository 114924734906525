window.bindNoteFormEvents = () => {
  const form = document.querySelector('#attachment-form');
  if (form) {
    form.addEventListener('submit', handleFormSubmit);
  }

  const attachmentInput = document.querySelector('#attachments-input');
  if (attachmentInput) {
    attachmentInput.addEventListener('change', () => {
      updateAttachmentList(attachmentInput);
    });
  }
}

const updateAttachmentList = (attachmentInput) => {
  const attachmentListContainer = document.querySelector('#attachments-list');
  attachmentListContainer.innerHTML = '';

  const attachmentList = document.createElement('ul');
  attachmentListContainer.appendChild(attachmentList);

  Array.from(attachmentInput.files).forEach(file => {
    const attachmentEntry = document.createElement('li');
    attachmentEntry.textContent = file.name;
    attachmentList.appendChild(attachmentEntry);
  });
}

const handleFormSubmit = async (event) => {
  event.preventDefault();
  const form = event.target;
  const formData = new FormData(form);
  const csrf_token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

  try {
    const response = await fetch(`${form.action}.js`, {
      method: form.method,
      body: formData,
      credentials: 'include',
      headers: {
        'X-CSRF-Token': csrf_token,
      }
    });

    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

    const scriptText = await response.text();
    executeResponseScript(scriptText);
  } catch (error) {
    console.error('Fetch error:', error);
  };
}

/**
 * This function is used to execute server-rendered scripts returned from AJAX calls.
 * Rails-ujs does this automatically for remote: true forms, but we need to do it manually
 * because we can't use remote: true for file uploads. The script returned from the server
 * is a js.erb template.
 * @param {string} scriptText - The text of the script to inject into the DOM
 */
const executeResponseScript = (scriptText) => {
  const script = document.createElement('script');

  script.setAttribute('nonce', cspNonce());
  script.text = scriptText;

  document.head.appendChild(script).parentNode.removeChild(script);
}

const cspNonce = () => {
  const metaTag = document.querySelector("meta[name=csp-nonce]")
  return nonce = metaTag && metaTag.content
}
