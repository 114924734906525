window.Flash = (function() {
  var hide = function() {
    setTimeout(function() {
      $(".flash").fadeOut('slow', function() {
        $(this).remove();
      });
    }, 3500);
  };

  return {
    hide: hide
  };
})();

$(function () {
  Flash.hide();
});

