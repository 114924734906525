import 'select2';

window.initializeSelect2 = function() {
  $(".dropdown-select").select2({
    minimumResultsForSearch: -1,
    placeholder: $("option:first-child", this).text(),
    allowClear: false,
  });

  $(".dropdown-select-with-search").select2({
    minimumResultsForSearch: 1,
    language: {
      noResults: () => $(this).data("no-results"),
    },
  });
}

window.initializeSelect2Disabled = function(id) {
  $(".dropdown-select" + id).select2({
    minimumResultsForSearch: -1,
    placeholder: $("option:first-child", this).text(),
    allowClear: false,
    disabled: true
  });

  $(".dropdown-select-with-search" + id).select2({
    minimumResultsForSearch: Infinity,
    language: {
      noResults: () => $(this).data("no-results"),
    },
    disabled: true
  });
}

$(function () {
  initializeSelect2();
});
