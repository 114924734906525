function buildOption(contact, selectedContactId) {
  var optionText = contact.name;

  if (contact.titles) {
    optionText += ' | ' + contact.titles;
  }

  if (contact.email) {
    optionText += ' | ' + contact.email;
  }

  var isSelected = contact.id === selectedContactId;
  return '<option value="' + contact.id + '"' + (isSelected ? ' selected' : '') + '>' + optionText + '</option>';
}


function loadContactsForCustomer(customerId, selectedContactId, placeholder) {
  if (customerId) {
    $.ajax({
      url: '/customers/' + customerId + '/contacts.json',
      success: function(data) {
        var options = `<option value="">${placeholder}</option>`;
        options += data.map(function(contact) {
          return buildOption(contact, selectedContactId);
        }).join('');

        $('#contact_select').html(options).select2();
      }
    });
  } else {
    $('#contact_select').html(`<option value="">${placeholder}</option>`).select2();
  }
}

$(document).ready(function() {
  var preselectedCustomerId = $('#customer_select').val();
  var preselectedContactId = $('#contact_select').data('selected-contact-id');
  var placeholder = $('#contact_select').data('placeholder');
  loadContactsForCustomer(preselectedCustomerId, preselectedContactId, placeholder);

  $('#customer_select').on('change', function() {
    var customerId = $(this).val();
    loadContactsForCustomer(customerId, placeholder);
  });
});