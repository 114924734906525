$(document).ready(function () {
  $('.load-more').click(function (e) {
      e.preventDefault();
      $('.load-more').hide();
      $('.ajax-spinner').show();
      var last_id = $('.root-activity').last().attr('data-id')
      $.ajax({
          type: "GET",
          url: $(this).attr('href'),
          data: {
              id: last_id
          },
          dataType: "script",
          success: function () {
              $('.ajax-spinner').hide();
              $('.load-more').show();
          }
      });
  });
});
