var MenuPanel = function() {
  var init = function() {
    $( document ).on( "mousemove", function( event ) {
      if (event.pageX < 10) {
        show_menu();
        console.log( "pageX: " + event.pageX + ", pageY: " + event.pageY );
      }
    });
  };

  var show_menu = function() {
    $('.navbar-column').toggle('slide');
  };

  return {
    init: init
  }
}();

$(document).ready(function() {
//  MenuPanel.init();
 
});
 