$(document).ready(function() {
  $( document ).ajaxStart(function() {
    var search_icon = $('#search_icon');
    search_icon.removeClass('fas fa-magnifying-glass');
    search_icon.addClass('fas fa-arrows-rotate fa-lg refresh-animate');
  });
  $( document ).ajaxStop(function() {
    var search_icon = $('#search_icon');
    search_icon.removeClass('fas fa-arrows-rotate fa-lg refresh-animate');
    search_icon.addClass('fas fa-magnifying-glass');
  });
});
