$(".new-customer").on("click", function(event){
  event.preventDefault();

  $.ajax({
    type: "GET",
    url: $(this).attr('href'),
    success: function(){
      displayOverlay();
      $('.nav-tabs .nav-link:not(.active)').toggleClass('disabled');
    }
 });
});

$("tr[class*='user-']").on("click", function(){
    $.ajax({
      type: "GET",
      url: $(this).attr('href'),
    });
});

$(document).on("click", "#contact-table tr", function(){
    $.ajax({
      type: "GET",
      url: $(this).attr('href'),
    });
});

$(document).on("click",".removeNiceAlert",function(){
  $(".niceAlert").remove()
});
$(document).on("click", ".save-btn", function(){
  if (
    $('#customer_name').val() &&
    $('#customer_address').val() &&
      $('#customer_zip_code').val()  &&
      $('#customer_city').val() &&
      ($("#hasCategory").val() == 1 ||
      getCategoryBool()==true)
  ) {
    $('.transparent-overlay, .overlay-content, .overlay-contact').fadeOut(300)
  } else if (getCategoryBool()!=true){
    $("body").append("<div class='niceAlert'><p>Fejl</p><p>"+$("#category_missing_message").val()+"</p><button class='removeNiceAlert'>OK</button></div>")
  }
});
$(document).on("click",".ov-close", function(){
  $('.transparent-overlay, .overlay-content, .overlay-contact').fadeOut(300)
});


$(document).on("click", ".customer-table tbody tr, .activity-customer-link", function(){
  var url = $('a', $(this)).first().attr('href');

  $('.transparent-overlay, .customer-ajax-spinner').show();
  $.ajax({
    type: "GET",
    url: url,
    success: function(){
      $('.customer-ajax-spinner').hide();
      displayOverlay();
    }
  });
});

function displayOverlay(){
  hideCategoriesAsCheckBoxes() // hide the lists just in frontend
  $('.transparent-overlay, .overlay-content').fadeIn(300)
}

window.toggleModal = function(id) {
  $(id).modal('toggle');
}

window.hideModal = function(id) {
  $(id).modal('hide');
}

var notIncludedAsList = ["råstoffer", "miljø", "råstoffer og miljø", "andre"];

// map new categories to list
$(document).on("click", ".customer-fields button[data-category]", function() {
  // Remove all primary categories as checked and reset to default images
  $(".list-checkbox").each(function(index) {
    var label = $(this).next('label').text().toLowerCase();
    
    if (notIncludedAsList.includes(label)) {
      $(this).prop("checked", false);
    }
  });

  $(".customer-fields button[data-category]").not(this).each(function() {
    var img = $(this).find('img');
    img.attr('src', $(this).data('default-img'));
  });

  // Change the image of the clicked button to its active state
  var clickedImg = $(this).find('img');
  clickedImg.attr('src', $(this).data('active-img'));

  // Set new category as checked
  var categoryText = $(this).attr("data-category").replaceAll("-", " ");
  $(".list-checkbox").each(function() {
    var label = $(this).next('label').text().toLowerCase();
    
    if (label == categoryText) {
      $(this).prop("checked", true);
      setCategoryBool(true);
    }
  });
});


/* 
  Hide the primary lists when loading the customer form
  This is done because the primary lists are not included in lists as checkboxes
*/
function hideCategoriesAsCheckBoxes() {
  $('.list-checkbox').each(function(index) {
    var label = $(this).next('label').text().toLowerCase();

    if (notIncludedAsList.includes(label)) {
      $('#list-item-' + index).removeClass('d-flex').hide();
    }
  });
}


// used to detect if a button is selected (triggered above - used onsubmit)
function setCategoryBool(bool){
  categoryBool=bool;
}

function getCategoryBool(){
  if (typeof categoryBool == 'undefined'){
    return false
  }
  return categoryBool
}



function changeAction(action){

  var currentAction =  $(".new_export").attr("action")
  var currentActionValue= currentAction.split("?")[0]
  var currentActionQuery= currentAction.split("?")[1]
  var currentActionValueName = currentActionValue.split(".")[0]

  if (action=="csv"){
   currentActionValueName = currentActionValueName + ".csv"
  } else {
    currentActionValueName = currentActionValueName + ".docx"
  }
  return currentActionValueName + "?" + currentActionQuery
}

$(document).on("change",".new_export input[name='export[export_type]']",function(){
  $(".new_export").attr("action",changeAction($(this).val()))
})
